.rss-preview {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.rss-preview h5 {
    margin-bottom: 10px;
}

.rss-preview div {
    margin-bottom: 10px;
}

.rss-preview img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
