.device-frame {
    border: 10px solid #000;
    border-radius: 20px;
    width: 240px; /* 2/3 of 320px */
    margin: 20px auto;
    padding: 10px;
    background: #000;
}

.side-menu-preview {
    background: #f7f7f7;
    border-radius: 8px;
}

.side-menu {
    background-color: black;
    padding: 10px;
    font-family: "Inter";
}

.side-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    background-color: rgba(0, 20, 37, 0.6)
}

.side-menu-item:last-child {
    border-bottom: none;
}

.section-header {
    font-size: 20px;
    background-color: transparent;
    /*border-bottom: 1px solid #ccc;*/
    overflow: visible; /* Ensure visibility for wrapped text */
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Break long words */
    word-break: break-all; /* Break all words */
    width: 100%; /* Ensure full width usage */
    flex-wrap: wrap; /* Ensure wrapping of text */
    min-height: 50px;
    display: flex;
    align-items: flex-end;
}
