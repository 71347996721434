.preview-container {
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    padding: 20px; /* Add some padding to make the previews look better */
    width: 100%;
    min-height: 100%; /* Adjust height as needed */
}

.preview-image {
    width: 100%;
}

.slider-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1); /* Light background */
    color: #333; /* Text color */
    font-size: 18px; /* Text size */
    font-weight: bold; /* Bold text */
    border: 2px dashed #999; /* Dashed border for a placeholder look */
}
