.notification-preview {
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 375px;
    margin: 20px auto;
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.notification-preview-header {
    background-color: #007AFF;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.notification-preview-body {
    display: flex;
    align-items: center;
    padding: 15px;
}

.notification-preview-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-right: 15px;
}

.notification-preview-content {
    flex-grow: 1;
}

.notification-preview-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.notification-preview-message {
    font-size: 14px;
    color: #555;
}
